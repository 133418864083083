<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">BANK TALLY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-select
            v-model="month_of"
            class="mx-3"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            label="Month Of"
            required
            @change="monthly_remittances"
            dense
          ></v-select>
        </v-col>
        Print:
        <v-icon
          class="mr-2"
          color="warning"
          @click="print_data()"
        >
          {{icons.mdiPrinter}}
        </v-icon>
      </v-row>
      <v-data-table dense
                    :headers="headers"
                    :items="collectors_data"
      >
        <template v-slot:item="{ item }">
          <tr
          >
            <td>{{ item.id }}</td>
            <td class="text-center">
              {{ item.date_of_deposit }}
            </td>
            <td class="text-center">
              {{ item.time_of_deposit }}
            </td>
            <td class="text-center">
              {{ item.amount }}
            </td>
            <td class="text-center">
              {{ item.bank_depositories.bank_code_name}}
            </td>
            <td class="text-center">
              {{ item.collector.last_name+', '+item.collector.first_name}}
            </td>
            <td class="text-center">
              <div>
                <div
                  v-if="item.status===null || item.status!='FOUND'">
                  <div v-if="!remittances_dialog">
                    <v-icon
                      class="mr-2"
                      color="warning"
                      @click="check_bank_tally(item)"
                    >
                      {{icons.mdiCheckCircle}}
                    </v-icon>
                    <v-chip color="error" v-if="item.status!=null">
                      {{ item.status}}
                    </v-chip>
                  </div>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
                <div v-else>
                  <v-chip v-if="item.status==='FOUND'" color="success">
                    {{ item.status}}
                  </v-chip>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiPrinter, mdiDelete
    , mdiEyeOutline
    , mdiCheckCircle
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import moment from 'moment'

  export default {
    components: {
      snackBarDialog,
    },
    mounted() {
      this.get_transaction_month_history({
        branch_id: this.branch_id,
      })
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiCheckCircle,
          mdiDelete,
          mdiEyeOutline,
        },
      }
    },
    data() {
      return {
        headers: [
          {text: 'ID', value: 'id', sortable: false},
          {text: 'DATE', align: 'center', value: 'date_of_deposit', sortable: false},
          {text: 'TIME', align: 'center', value: 'time_of_deposit', sortable: false},
          {text: 'AMOUNT', align: 'center', value: 'amount', sortable: false},
          {text: 'BANK', align: 'center', value: 'bank_code_name', sortable: false},
          {text: 'COLLECTOR', align: 'center', value: 'bank_code_name', sortable: false},
          {text: 'STATUS', align: 'center', value: 'bank_code_name', sortable: false},
        ],
        headers_remittance: [
          {text: 'OR DATE', value: 'id', sortable: false},
          {text: 'OR NO', value: 'date_of_deposit', sortable: false},
          {text: 'NAME', value: 'time_of_deposit', sortable: false},
          {text: 'AMOUNT', value: 'amount', sortable: false},
          {text: 'REMARKS', value: 'bank_code_name', sortable: false},],
        remittances_dialog: false,

        data: [],
        collectors_data: [],
        collectors_remittances_data: [],

        month_of_items: [],
        month_of: '',
        collectors_items: [],
        collector_id: 0,
        selectedDepositId: -1,
      }
    },
    computed: {
      ...mapGetters('authentication', ['position', 'branch_id','branch']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months_history', ['get_transaction_month_history']),
      ...mapActions('deposit_slip', ['remittances_data_for_bank_tally',  'bank_tally']),
      monthly_remittances() {
        this.remittances_dialog = false
        var index = this.month_of_items.map(function (x) {
          return x.id;
        }).indexOf(this.month_of)
        if (index != -1) {
          const data = new FormData()
          data.append('month_of', this.month_of_items[index].month_of);
          data.append('branch_id', this.branch_id);
          this.remittances_data_for_bank_tally(data)
            .then(response => {
              this.collector_id = 0
              this.selectedDepositId = -1
              this.collectors_data = []
              this.collectors_remittances_data = []
              this.collectors_data = response.data
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      check_bank_tally(item) {
        this.remittances_dialog = true
        const data = new FormData()
        data.append('id', item.id);
        this.bank_tally(data)
          .then(() => {
            this.monthly_remittances()
          })
          .catch(error => {
            console.log(error)
          })
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        array.push(
          [
            {text: 'ID', alignment: 'center', style: 'label'},
            {text: 'DATE', alignment: 'center', style: 'label'},
            {text: 'TIME', alignment: 'center', style: 'label'},
            {text: 'AMOUNT', alignment: 'center', style: 'label'},
            {text: 'BANK', alignment: 'center', style: 'label'},
            {text: 'COLLECTOR', alignment: 'center', style: 'label'},
            {text: 'STATUS', alignment: 'center', style: 'label'},
          ]
        )
        if (this.collectors_data.length > 0) {
          this.collectors_data.forEach(function (item) {
            if (item.status!='FOUND'){
              array.push(
                [
                  {text: item.id, alignment: 'center'},
                  {text: item.date_of_deposit, alignment: 'left'},
                  {text: item.time_of_deposit, alignment: 'left'},
                  {
                    text: (item.amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left'
                  },
                  {text: item.bank_depositories.bank_code_name, alignment: 'left'},
                  {
                    text: item.collector.last_name + ', ' + item.collector.first_name,
                    alignment: 'left'
                  },
                  {
                    text: item.status,
                    alignment: 'left'
                  },

                ]
              )
            }
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'BANK TALLY', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [40, 50, 40, 50, 120,120,50],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
